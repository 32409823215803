import { EditIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import settings from '../../settings';

const EditButton = ({ printer, handleEditClick }) => {
  if (settings.editablePrinterDestinations.includes(printer.destination)) {
    return (
      <>
        <IconButton
          data-testid="edit-button"
          aria-label="Edit printer"
          icon={<EditIcon />}
          onClick={e => handleEditClick(printer, e)}
        />
      </>
    );
  }
};
export default EditButton;
