import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ManagePrintersContainer from './containers/ManagePrintersContainer';
import { colors } from './theme';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const theme = extendTheme({
    colors: {
      colors,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        {!isAuthenticated && !isLoading ? (
          loginWithRedirect()
        ) : (
          <Routes>
            <Route path="/" element={<ManagePrintersContainer />} />
          </Routes>
        )}
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
