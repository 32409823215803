import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

const Loader = ({ size = 'xl' }) => {
  return (
    <Center pt="10" pb="10">
      <Spinner
        thickness={size === 'xl' ? '4px' : '2px'}
        speed="0.65s"
        emptyColor="colors.darkestTealGrey"
        color="colors.primary"
        size={size}
        data-testid="loading-spinner"
      />
    </Center>
  );
};

export default Loader;
