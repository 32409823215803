import settings from '../settings';

const getPrinters = async tokenMemo => {
  try {
    const token = await tokenMemo;

    const response = await fetch(settings.apiV1Printers, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return await response.json();
  } catch (e) {
    console.error(e); //eslint-disable-line
  }
};
export default getPrinters;
