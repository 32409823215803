import settings from '../settings';

const postNewPrinter = async (tokenMemo, body) => {
  const token = await tokenMemo;

  try {
    const response = await fetch(settings.apiV1Printers, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    return response;
  } catch (e) {
    console.error(e); //eslint-disable-line
  }
};
export { postNewPrinter };
