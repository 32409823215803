import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import Loader from '../layout/Loader';
import UpsertPrinterModal from './UpsertPrinterModal';
import ErrorModal from './ErrorModal';
import SuccessModal from './SuccessModal';

const PrinterModalContainer = ({ isOpen, onClose, printer, setNewPrinter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [hasSent, setHasSent] = useState(false);

  const handleClose = e => {
    setHasSent(false);
    setError();
    setNewPrinter();
    onClose(e);
  };

  if (isLoading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay data-testid="overlay" />
        <ModalContent>
          <ModalCloseButton data-testid="close" />
          <Loader />
        </ModalContent>
      </Modal>
    );
  }

  if (error) {
    return <ErrorModal error={error} isOpen={isOpen} onClose={handleClose} />;
  }
  if (hasSent) {
    return <SuccessModal onClose={handleClose} isOpen={isOpen} />;
  }
  return (
    <UpsertPrinterModal
      setIsLoading={setIsLoading}
      setError={setError}
      setHasSent={setHasSent}
      onClose={handleClose}
      isOpen={isOpen}
      printer={printer}
      setNewPrinter={setNewPrinter}
    />
  );
};
export default PrinterModalContainer;
