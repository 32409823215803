//* API
const API_HOST_V1 =
  process.env.REACT_APP_API_HOST_V1 || window.ENV_CONFIG.hosts.api;

const API_HOST_V1_PRINTERS = `${API_HOST_V1}/printers`;

//* External Links
const MRM_TOOLKIT_URL =
  process.env.REACT_APP_MRM_TOOLKIT_URL || window.ENV_CONFIG.mrmToolkitUrl;

//* Auth0 Config
const AUTH0_AUDIENCE =
  process.env.REACT_APP_AUTH0_AUDIENCE_DEV || window.ENV_CONFIG.auth0.audience;
const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN_DEV || window.ENV_CONFIG.auth0.domain;
const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID_DEV ||
  window.ENV_CONFIG.auth0.client_id;

const manualPrinterIDs = [6, 12, 18, 17];
const editablePrinterDestinations = ['synergy-commerce'];

const settings = {
  apiHostV1: API_HOST_V1,
  apiV1Printers: API_HOST_V1_PRINTERS,
  mrmToolkitUrl: MRM_TOOLKIT_URL,
  audience: AUTH0_AUDIENCE,
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  manualPrinterIDs,
  editablePrinterDestinations,
};
export default settings;
