import { useAuth0 } from '@auth0/auth0-react';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import getPrinters from '../../api/getPrinters';
import settings from '../../settings';
import Loader from '../layout/Loader';
import PrinterModalContainer from '../printerModal/PrinterModalContainer';
import EditButton from './EditButton';
import sortAlphabetically from './sortAlphabetically';

const PrintersTable = ({ newPrinter }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [printers, setPrinters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [printerToEdit, setPrinterToEdit] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const tokenMemo = Promise.resolve(
    useMemo(async () => {
      return await getAccessTokenSilently({
        audience: settings.audience,
      });
    }, [getAccessTokenSilently])
  );

  const loadPrinters = useCallback(
    async tokenMemo => await getPrinters(tokenMemo),
    []
  );

  useLayoutEffect(() => {
    async function fetchPrinters() {
      const printersArray = await loadPrinters(tokenMemo);

      setPrinters(printersArray.sort(sortAlphabetically).filter(p => p.active));
      setIsLoading(false);
    }
    fetchPrinters();
  }, [tokenMemo, loadPrinters]);

  useEffect(() => {
    if (newPrinter) {
      setPrinters(
        [...printers, newPrinter].sort(sortAlphabetically).filter(p => p.active)
      );
    }
  }, [newPrinter]); //eslint-disable-line

  const handlePrinterEdit = editedPrinter => {
    setPrinters(
      printers
        .map(p => {
          if (p.id === editedPrinter?.id) return editedPrinter;
          return p;
        })
        .sort(sortAlphabetically)
    );
  };

  const handleEditClick = (printer, e) => {
    setPrinterToEdit(printer);
    onOpen(e);
  };

  const handleClose = () => {
    setPrinterToEdit();
    onClose();
  };

  if (isLoading) return <Loader />;

  return (
    <TableContainer borderTop="1px" borderColor="#F3F4F6">
      <PrinterModalContainer
        isOpen={isOpen}
        onClose={handleClose}
        printer={printerToEdit}
        setNewPrinter={handlePrinterEdit}
      />

      <Table>
        <Thead>
          <Tr>
            <Th textTransform="none">Printer ID</Th>
            <Th textTransform="none">Name</Th>
            <Th textTransform="none">Destination</Th>
            <Th textTransform="none">Currency</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {printers.map(p => (
            <Tr key={p.id}>
              <Td>{p.id}</Td>
              <Td>{p.name}</Td>
              <Td>{p.destination}</Td>
              <Td>{p.currency}</Td>
              <Td>
                <EditButton printer={p} handleEditClick={handleEditClick} />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
export default PrintersTable;
