import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { postNewPrinter } from '../../api/postNewPrinter';
import settings from '../../settings';
import TextField from './TextField';

const defaultDestination = 'synergy-commerce';

const UpsertPrinterModal = ({
  setIsLoading,
  setError,
  setHasSent,
  onClose,
  isOpen,
  printer,
  setNewPrinter,
}) => {
  const [name, setName] = useState('');
  const [currency, setCurrency] = useState('');
  const [destination, setDestination] = useState('');
  const [printerId, setPrinterId] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (printer) {
      setName(printer.name);
      setCurrency(printer.currency);
      setDestination(printer.destination);
      setPrinterId(printer.id);
    } else {
      setName('');
      setDestination(defaultDestination);
      setPrinterId();
      setCurrency('');
    }
  }, [printer]);

  const tokenMemo = Promise.resolve(
    useMemo(async () => {
      const token = await getAccessTokenSilently({
        audience: settings.audience,
      });

      return token;
    }, [getAccessTokenSilently])
  );

  const handleSubmit = async () => {
    const body = {
      name,
      destination,
      currency,
      email: `devsmrmbrand+${name.replaceAll(' ', '_')}@gmail.com`,
    };
    if (printer) {
      body.id = printerId;
    }
    setIsLoading(true);
    try {
      const response = await postNewPrinter(tokenMemo, body);

      const responseBody = await response.json();
      if (response.status === 200) {
        setHasSent(true);
        setNewPrinter(responseBody);
      } else {
        setError(responseBody.error);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e); //eslint-disable-line
      setError(e);
      setIsLoading(false);
    }
  };

  const isInvalid = !name || !destination || !currency;

  const handleClose = e => {
    setName('');
    setDestination(defaultDestination);
    onClose(e);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay data-testid="overlay" />
      <ModalContent>
        <ModalHeader fontSize="16px" lineHeight="24px">
          {`${printer ? 'Edit' : 'Add'} Printer`}
        </ModalHeader>
        <ModalCloseButton data-testid="close" />
        <ModalBody>
          {printer && (
            <TextField
              fieldName="ID"
              value={printerId}
              setValue={setPrinterId}
              isDisabled={true}
            />
          )}
          <TextField fieldName="Name" value={name} setValue={setName} />
          <TextField
            fieldName="Destination"
            value={destination}
            setValue={setDestination}
          />
          <TextField
            fieldName="Currency"
            value={currency}
            setValue={setCurrency}
          />
        </ModalBody>
        <ModalFooter justifyContent="left">
          <Button mr={3} onClick={handleClose} data-testid="cancel">
            Cancel
          </Button>
          <Button
            backgroundColor="colors.primary"
            color="colors.white"
            isDisabled={isInvalid}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpsertPrinterModal;
