import { Box, Flex, useDisclosure, Heading } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import PrinterModalContainer from '../components/printerModal/PrinterModalContainer';
import PrintersTable from '../components/table/PrintersTable';
import { useState } from 'react';
import Header from '../components/layout/Header';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';

const ManagePrintersContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newPrinter, setNewPrinter] = useState();
  return (
    <>
      <Header />
      <Flex direction={'column'} p={10} gap={6}>
        <Flex h={50} justifyContent="space-between" w={'100%'}>
          <Heading color={'colors.darkestTealGrey'}>Manage Printers</Heading>
          <Flex padding="6px">
            <PrimaryButton text={'Add'} icon={AddIcon} onClick={onOpen} />
          </Flex>
        </Flex>

        <Flex
          direction={'column'}
          h={'calc(100vh - 260px)'}
          border={'1px'}
          borderColor={'colors.darkestTealGrey'}
          borderRadius={5}
          pt={6}
        >
          <Flex flexDirection="column" margin="0 10px" overflowX="scroll">
            <Box marginBottom="21px">
              <PrinterModalContainer
                isOpen={isOpen}
                onClose={onClose}
                setNewPrinter={setNewPrinter}
              />
              <PrintersTable newPrinter={newPrinter} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ManagePrintersContainer;
