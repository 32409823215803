import { WarningIcon } from '@chakra-ui/icons';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

const ErrorModal = ({ error, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay data-testid="overlay" />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton data-testid="close" />
        <ModalBody textAlign="center">
          <WarningIcon color="colors.error" width="40px" height="40px" />
          <Text
            marginTop="12px"
            fontWeight="700"
            fontSize="22px"
            lineHeight="33px"
          >
            Error!
          </Text>
          <Text
            marginTop="8px"
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
          >
            {error}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="colors.primary"
            color="colors.white"
            onClick={onClose}
            width="100%"
          >
            Go back to dashboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
