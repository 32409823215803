import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useState } from 'react';

const TextField = ({
  isRequired = true,
  isDisabled = false,
  fieldName,
  value,
  setValue,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [isInvalid, setIsInvalid] = useState();
  const handleChange = e => {
    setValue(e.target.value);
    if (!e.target.value) {
      setErrorMessage(`${fieldName} is required.`);
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
  };

  const handleBlur = e => {
    if (!value) {
      setErrorMessage(`${fieldName} is required.`);
      setIsInvalid(true);
      return;
    }
  };

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
    >
      <FormLabel>{fieldName}</FormLabel>
      <Input
        type="text"
        onChange={handleChange}
        defaultValue={value}
        onBlur={handleBlur}
        data-testid={`input-${fieldName}`}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
export default TextField;
