export const colors = {
  primary: '#009FB5',
  secondary: '#0A92A5',
  white: '#F5F5F5',
  black: '#111827',
  error: '#EF4444',
  lightestGrey: '#E8E8E8',
  lightTealGrey: '#BFC9CB',
  darkTealGrey: '#363D41',
  pastelTeal: '#7ADCF0',
  medTeal: '#009FB5',
  mrmTeal: '#006873',
  brightTeal: '#20C8DA',
  medDarkTeal: '#0A92A5',
  darkestTeal: '#0B252B',
  darkestTealGrey: '#21292D',
  charcoalGrey: '#84878B',
};

export const gradients = {
  blueMartini: [
    'linear-gradient(270.4deg, #1BB9CF -3.87%, #00A0B6 52%, #0E86E6 102.86%);',
    'linear-gradient(90deg, #1BB9CF -3.87%, #00A0B6 52%, #0E86E6 102.86%);',
  ],
};
